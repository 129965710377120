
    <!-- 耕雲導師說法視頻 -->
  <template>
  <!-- 耕雲導師讲词 -->
  <div class="word">
    <table border="1" cellspacing="0">
      <tr v-for="(item, index) in videoList" :key="index">
        <td class="leftInfo" style="max-width: 300px !important ">
          <div
            class="titleName"
            style="margin-left: 20px; white-space: pre-wrap"
          >
            {{ $t("numList1")[index] }}、{{ item.videos_title }}
          </div>
          <div
            style="margin-left: 20px; white-space: pre-wrap"
            v-html="item.videos_brief"
          ></div>
          <!-- <div class="downLoad" @click="downLoad(item)">
            {{ $t("downLoad") }}
          </div> -->
        </td>
        <td class="contentTitle video">
          <img
            src="http://www.anhsiangchan.org.tw//uploads/one.png"
            v-if="index === 0 && !videoFlag1"
            alt=""
            class="image1"
            @click="payVideo1"
          />
          <img
            class="open1"
            v-if="index === 0 && !videoFlag1"
            @click="payVideo1"
            src="../../static/img/open.png"
            alt=""
          />
          <img
            class="open2"
            v-if="index === 1 && !videoFlag2"
            @click="payVideo2"
            src="../../static/img/open.png"
            alt=""
          />
          <img
            src="http://www.anhsiangchan.org.tw/uploads/two.png"
            v-if="index === 1 && !videoFlag2"
            alt=""
            class="image1"
            @click="payVideo2"
          />
          <video
            v-if="index === 0 && videoFlag1"
            :src="item.videos_url"
            :controls="true"
            :muted="false"
            autoplay
            id="video1"
          ></video>
          <video
            v-if="index === 1 && videoFlag2"
            :src="item.videos_url"
            :controls="true"
            :muted="false"
            autoplay
            id="video2"
          ></video>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getCateList, getVideoList } from "../../api/apis";
export default {
  name: "",
  components: {},
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // baseUrl: "http://chanxue.b.langqiyun.cn/api/index/fileDownload?",
      baseUrl: "http://www.anhsiangchan.org.tw/api/index/fileDownload?",
      videoList: [],
      image1: require("../../assets/图片1.png"),
      image2: require("../../assets/图片2.png"),
      videoFlag1: false,
      videoFlag2: false,
    };
  },
  watch: {
    videoFlag1(val) {
      if (val) {
        let that = this;
        that.$nextTick(() => {
          var elevideo = document.getElementById("video1");
          elevideo.addEventListener(
            "ended",
            function () {
              that.videoFlag1 = false;
            },
            false
          );
        });
      }
    },
    videoFlag2(val) {
      if (val) {
        let that = this;
        that.$nextTick(() => {
          var elevideo = document.getElementById("video2");
          elevideo.addEventListener(
            "ended",
            function () {
              that.videoFlag2 = false;
            },
            false
          );
        });
      }
    },
  },
  methods: {
    payVideo1() {
      this.videoFlag1 = true;
    },
    payVideo2() {
      this.videoFlag2 = true;
    },
    //文章详情
    linkToDetail(item) {
      window.open(
        this.$BaseURL +
          "/articleDetail?name=word&id=" +
          item.id +
          "&title=" +
          this.title
      );
    },
    // 下载
    downLoad(item) {
      window.location.href =
        this.baseUrl + `url=${item.download_url}&title=${item.videos_title}`;
      // videosDownload(item.id).then(res => {
      //     console.log('下载成功111',res);
      // })
      // window.location.href = id;
      // videosDownload({file_title:"xz",file_url:item.videos_url}).then(res => {
      //     console.log('下载成功',res);
      // })
    },
  },
  created() {
    getCateList().then((res) => {
      let id = res[1][4].id;
      getVideoList(id).then((res) => {
        for (let i = 0; i < res.length; i++) {
          res[i].size = (parseInt(res[i].size) / 1024 / 1024).toFixed(2);
        }

        this.videoList = res;
      });
    });
  },
};
</script>

<style lang="stylus" scoped>
.ta-l {
  text-align: left;
}

.pl5 {
  padding-left: 5px;
}

.word {
  width: 750px;
  margin: 20px 10px;

  .title {
    font-size: 15px;
    font-weight: 600;
  }
}

table, td, tr, th {
  border: 1px solid #ACACAC;
}

table {
  width: 100%;
  font-size: 15px;

  th {
    line-height: 36px;
    font-weight: 600;
    padding-left: 15px;
  }

  td {
    height: 320px;
    vertical-align: middle;
  }

  .leftInfo {
    width: 60px;
  }

  .leftInfo, .contentTitle {
    width: 50%;
    font-size: 15px;
  }
}

.leftInfo {
  text-align: left;
  padding-left: 3px;
  line-height: 35px;

  .titleName {
    color: #000000;
    font-size: 15px;
    line-height: 28px;
    font-weight: 600;
  }

  .brief {
    width: 50% !important;
    white-space: pre-wrap;
  }

  .fileInfo {
    p {
      display: inline-block;
      margin-right: 20px;
    }
  }

  .downLoad {
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 30px;
  }

  .downLoad:hover {
    color: #1482d9;
  }

  .belong {
  }
}

.contentTitle {
  position: relative;

  .open1 {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 3;
    top: 75%;
    left: 5%;
    // transform: translate(-50%, -50%);
  }

  .open2 {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 3;
    top: 75%;
    left: 5%;
    // transform: translate(-50%, -50%);
  }

  .image1 {
    position: absolute;
    width: 352px;
    height: 240px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image2 {
    position: absolute;
    width: 352px;
    height: 240px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: 0;
    bottom: 0;
  }
}
</style>